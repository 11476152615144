// include externe css/scss files zoals bootstrap
@import "scss/includes/bootstrap";
@import "scss/includes/font-awesome";

// include variables
@import "scss/colors";

// include styles
@import "scss/mixins";
@import "scss/fontfaces";
@import "scss/bulk";
@import "scss/reusables";
@import "scss/overwrites";