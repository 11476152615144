.listBox {
	width: 100%;
	border-color: $lightgreen;
	border-width: 2px;
	border-top-width: 55px;
	border-style: solid;
	margin-top: 20px;

	h2 {
		margin: -55px 0px 0px 0px;
		padding: 0px 0px 0px 30px;
		line-height: 55px;
		font-size: 20px;
		color: $white;
		text-transform: uppercase;
	}

	ul {
		list-style: none;
		margin: 0px;
		padding: 25px;
		font-size: 15pt;

		li {
			padding-bottom: 6px;

			a {
				color: $lightgreen;
			}
		}
	}

	table {
		margin: 25px;
		font-size: 17px;

		tbody {
			tr {
				td {
					padding-bottom: 6px;

					a {
						color: $lightgreen;
					}

					p {
						font-size: 12px;
					}
				}
			}
		}
	}
}

.returnButton, .downloadButton {
	a {
		@include colorBar(50px, $lightgreen);
		display: block;
		line-height: 50px;
		font-size: 17px;
		color: $white;
		text-decoration: none;
		text-align: center;
		transition: background-color 0.2s linear, color 0.2s linear, border 0.2s linear;
	}

	span {
		line-height: 50px;
		color: $white;
		float: left;
		position: absolute;
		font-size: 20px;
		padding-left: 15px;
		transition: color 0.2s linear;
	}

	&:hover {
		a {
			background-color: $white;
			border: solid 1px $lightgreen;
		}

		a, span {
			color: $lightgreen;
		}
	}
}

.downloadButton {
	a {
		@include colorBar(30px, $lightgreen);
		line-height: 30px;
		font-size: 15px;
	}

	span {
		line-height: 30px;
		font-size: 18px;
	}
}

.propsContainer {
	td {
		vertical-align: top;

		&:nth-child(1) {
			width: 200px;
			color: $darkgreen;
			font-weight: 600;
		}
	}
}

table.overview {
	width: 100%;
	border: rgba(125, 165, 122, 0.5) solid 1px;

	tr {

		&:nth-child(even) { background: #f0f0f0 }

		opacity: 1;
		line-height: 27px;
		transition: opacity 0.4s ease;

		th {
			background-color: $lightgray;
			color: $white;
			padding-left: 10px;

			&:not(:last-child) {
				border-right: $white solid 1px;
			}
		}

		td {
			border: solid 1px #f0f0f0;
			padding: 10px;
			transition: line-height 0.3s ease; 
			border: solid 1px #888888;

		}

		&.sub {
			background-color: $lightgreen;
			color: $black;
			font-weight: bold;

			td:not(:last-child) {
				border-right: $white solid 1px;
			}
		}

		&.disabled {
			opacity: 0;
			line-height: 0px;
			display: none;

			td {
				line-height: 0px;
			}
		}

		&:not(.sub) {
			cursor: pointer;

			&:hover {
				background-color: $hoverwhite;
			}
		}
	} 
}

form.loginForm {
	background-color: $superlightgray;
	padding: 45px;
	border-radius: 2px;

	h2 {
		color: $darkgreen;
		margin: -20px;
		margin-bottom: 30px;
	}

	h3 {
		font-size: 17px;
		margin: 20px 0px 0px 0px;
	}

	input[type="text"], input[type="password"] {
		width: 100%;
		padding: 15px;
		font-size: 19px;
	}

	input[type="submit"] {
		margin-top: 50px;
		padding: 10px 50px;
		font-size: 18px;
		background-color: $lightgreen;
		border: $darkgreen 4px solid;
		border-radius: 4px;
		color: $white;
	}

	p.errorMessage {
		font-size: 18px;
		color: $darkgreen;
		padding-top: 5px;
	}
}

.giraTable, #actualContent {
	table {
		width: 100%;
		margin-top: 50px;

		tr:first-child  {
			height: 150px;
			background-color: $lightgray;
			color: $white;
			min-width: 20px;
			border: #888888 1px solid;
			cursor: pointer;
			opacity: 1;
			transition: opacity 0.15s ease;
			font-size: 10px;
	        font-weight: 500;


			&:hover {
				opacity: 0.85;
			}

			span {
				position: absolute;
			}

			&.flipContainer {
				overflow: hidden;

				.flipMe {
					transform-origin: 0% 50%;
					transform: rotate(90deg) translate(-65%, -50%);
				}

				:not(.flipMe) {
					padding-left: 20px;
				}
			}

			&.header {
				span:after {
					content: "\f0dc";
					font-family: FontAwesome;
					font-style: normal;
					font-weight: normal;
					text-decoration: inherit;
					padding: 5px;
				}

				&.headerSortDown span:after {
					content: "\f0dd";
				}

				&.headerSortUp span:after {
					content: "\f0de";
				}

				span.flipMe:after {
					display: inline-block;
					transform: rotate(-90deg) translate(0px, 5px);
				}
			}
		}

		tr:nth-child(even) {
			background-color: $superlightgray;
		}

		tr:first-child  { font-weight:bold }

		td, th  {
			padding: 8px;
	        border: solid 1px #888888;
	        vertical-align: top;
		}
	}
}