@mixin colorBar($height: 50px, $color: $white) {
	width: 100%;
	height: $height;
	background-color: $color;
}

@mixin centerH($width: 50%) {
	width: $width;
	margin: auto;
}

@mixin centerV {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}