* {
	font-family: 'roboto', sans-serif; 
	line-height: 21px;
}

#mainContent {
	padding: 40px 40px 200px 40px;
	background-color: #ffffff;
    box-shadow: 0px 2px 5px #888888;

    .home ul li { list-style: none;
    	    	  font-size: 18px;
	    		  line-height: 36px;
	}

    .home ul li:before {
	    content: "\f00c";
	    color: $darkgreen;
	    font-family: FontAwesome;
	    font-size: 18px;
	    line-height: 36px;
	    text-decoration: inherit;
	    margin-right: 10px;
	}

}

nav.navbar {
	height: 125px;
	background-color: $white;
	border-radius: 0px;

	img.brandLogo {
		width: 250px;
		margin-left: 70px;
	}

	ul.menuItems {
		@include centerV;
		position: absolute;
		right: 0px;

		li {
			list-style: none;
			float: left;
			font-size: 18px;
			margin-right: 20px;

			a {
				color: $lightgreen;
				text-decoration: none;
				height: 25px;

				&:after {
					content: "";
					width: 100%;
					height: 1px;
					transform: scaleX(0);
					background-color: $lightgreen;
					transition: transform 0.2s linear;
					margin-top: 3px;
					display: block;
				}

				&:hover:after {
					transform: scaleX(1);
				}
			}

			&.login-btn {
				background-color: $darkgreen;
				width: 160px;
				height: 40px;
				margin-left: 100px;
				text-align: center;
				line-height: normal;
				padding-top: 8px;

				a {
					color: $white;
					vertical-align: middle;
				}
			}
		}
	}
}

.banner {
	position: relative;
	width: 100%;
	height: 400px;
	overflow: hidden;
	border-top: solid 1px $darkgreen;

	img {
		width: 100%;
		position: absolute;
		bottom: 0px;
	}

	.bannerCover {
		@include colorBar(100%, $lightgreen);
		position: absolute;
	}

	.bar {
		@include colorBar(3px, $darkgreen);
		position: absolute;
		bottom: 0px;
	}


	.caption {
	    position: absolute;
	    top: 30%;
	    width: 100%;
	    text-align: center;
	    color: #FFF;
	    z-index: 9999;
	    color : $white;
	    opacity: 0.9;

	    h1 { font-size:48px ; line-height: 72px }
	    h1 { font-size:32px ; line-height: 48px }
 	}

	.navBlocks {
		.block {
			padding: 45px 50px 0px 50px;

			a {
				@include colorBar(90px, $white);
				display: block;
				line-height: 90px;
				border-radius: 10px;
				border: 5px solid $darkgreen;
				color: $lightgreen;
				text-decoration: none;
				text-align: center;
				font-size: 16px;
				text-transform: uppercase;
			}
		}
	}

	&.smallNav {
		height: 200px;

		.navBlocks {
			margin-top: 105px;

			.block {
				padding: 0px 10px;

				a {
					height: 100%;
					border-bottom-left-radius: 0px;
					border-bottom-right-radius: 0px;
					border-bottom: none;
					font-size: 15px;
				}

				&.active {
					z-index: 10;
				}
			}
		}

		.bar {
			height: 4px;
		}

		&.filter {
			.navBlocks {
				transition: margin-top 0.2s linear;

				.block {
					a {
						transition: line-height 0.2s ease, margin-top 0.2s linear, background-color 0.1s linear;
						background-color: $disabled;
					}

					&.active {
						a {
							background-color: $white;
						}

						&:after {
							opacity: 1;
							transition-delay: 0.2s;
							transition-duration: 0.3s;
						}
					} 

					&:not(.active) a {
						margin-top: 20px;
						line-height: 72px;
					}

					&:after {
						content: "\f057";
						font-family: FontAwesome;
						font-style: normal;
						font-weight: normal;
						text-decoration: inherit;
						font-size: 20px;
						position: absolute;
						top: 6px;
						right: 22px;
						pointer-events: none;
						color: $lightgreen;
						opacity: 0;
						transition: opacity 0.1s linear;
						transition-delay: 0s;
					}
				}

				&.allActive {
					margin-top: 125px;

					.block {

						a {
							margin-top: 0px;
							line-height: 72px;
							background-color: $white;
						}
					}
				}
			}
		}
	}
}

.searchBarContainer {
	@include colorBar(100px, $lightgray);

	.searchBarCenter {
		@include centerH(740px);
		position: relative;
		height: 100%;

		span {
			@include centerV;
			font-size: 24pt;
			float: left;
			color: $white;
		}

		form {
			height: 100%;

			input[type='text'] {
				@include centerV;
				background-color: $white;
				width: 560px;
				height: 50px;
				float: right;
			}
		}

		i.fa-search {
			@include centerV;
			position: absolute;
			font-size: 30pt;
			right: 10px;
		}
	}
}

ul.breadcrumbs {
	margin: 0px;
	padding: 0;
	height: 60px;
	list-style-type: none;
	font-size: 25px;
	text-transform: lowercase;
	border-bottom: 1px solid $lightgreen;
    margin-bottom: 30px;
    text-transform: uppercase;

	li {
		float: left;

		a {
			color: $darkgreen;
			text-decoration: none;
			padding: 5px;
		}

		&:not(:last-child):after {
			content: "\f105";
			font-family: FontAwesome;
			color: $lightgreen;
			display: inline-block;
			transform: scale(1, 2);
			padding: 0px 5px;
		}
	}
}

footer {
	width: 100%;
	color: $white;

	.firstBar {
		@include colorBar(25px, $lightgray);
	}

	.secondBar {
		@include colorBar(25px, $darkgreen);
	}

	.thirdBar {
		@include colorBar(150px, $lightgreen);
	}

	.copyright {
		padding-top: 15px;
		font-size: 16pt;
	}

	.contact {
		padding-top: 15px;
		font-size: 12pt;
	}

	a {
		color: $white;
	}
}